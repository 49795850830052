<template>
    <b-container>
        <div class="mb-3 d-flex justify-content-between">
            <div>Últimos contratos inseridos</div>
            <b-form @submit.prevent="requestData" inline>

                <b-button @click.prevent="requestData" variant="default" class="mr-3" ><b-icon-arrow-repeat></b-icon-arrow-repeat></b-button>

                <b-form-input
                    type="search"
                    v-model="search"
                    @input="onInputSearch"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Encontre um cliente"
                ></b-form-input>
                <b-button type="submit" variant="primary">
                    <b-icon-search></b-icon-search>
                </b-button>
            </b-form>
        </div>

        <div v-if="clients.length === 0 && errorMessage === ''"
             class="mt-3 d-flex justify-content-center align-items-center">
            <b-spinner class="mr-2" variant="primary"></b-spinner>
            {{(search === '' ? 'Carregando...': 'Buscando...')}}
        </div>

        <b-alert :show="errorMessage !== ''" variant="danger">
            {{errorMessage}}
            <a href="/" @click.prevent="requestData">Atualizar</a>
            </b-alert>

        <b-card class="mb-3" v-for="(c, key) in clients" :key="key">
            <b-card-title>
                <b-link style="color: #000; font-family: 'Open Sans', sans-serif " href="#"
                        @click.prevent="onClickTitle(c.Nome.toString())">
                    {{c.Codigo}} - {{c.Nome.toString().toUpperCase()}}
                </b-link>
            </b-card-title>

            <table class="table mb-0 table-hover ">
                <thead>
                <tr>
                    <th style="width: 30px" class="text-center"></th>
                    <th style="width: 30px">Contrato</th>
                    <th style="width: 120px">Data</th>
                    <th>Plano</th>
                    <th style="width: 250px">Vendedor</th>
                    <th style="width: 30px"></th>
                </tr>
                </thead>
                <tbody>
                <fragment v-for="(ctr, k) in c.Contratos.Pacotes" :key="k">
                    <tr>
                        <td colspan="6" class="text-primary"><strong>PACOTE</strong> - {{k}}</td>
                    </tr>
                    <tr v-for="(contract) in ctr" :key="contract.Numero">
                        <td :title="getStatus(contract.Situacao)" v-b-tooltip>
                            <b-icon-arrow-return-right class="mr-3"></b-icon-arrow-return-right>

                            <b-icon-check-circle-fill v-if="contract.Situacao === 'A'"
                                                      variant="success"></b-icon-check-circle-fill>
                            <b-icon-exclamation-circle-fill v-else
                                                            :variant="variantColor(contract.Situacao)"></b-icon-exclamation-circle-fill>
                        </td>
                        <td>{{contract.Numero}}</td>
                        <td>{{contract.Inicio | moment('DD/MM/YYYY')}}</td>
                        <td :title="contract.dados_plano.Descricao">{{contract.dados_plano.Codigo}} -
                            {{contract.dados_plano.Descricao}}
                        </td>
                        <td>{{contract.Vendedor.toString().toUpperCase()}}</td>
                        <td>
                            <b-button @click.prevent="printer(contract.Numero)" size="sm" variant="default">
                                <b-icon-printer :class="{'text-primary': hasPrinter(contract.Numero)}"></b-icon-printer>
                            </b-button>
                        </td>
                    </tr>
                </fragment>
                <fragment v-for="(plan, kk) in c.Contratos.Planos" :key="kk">
                    <tr>
                        <td :title="getStatus(plan.Situacao)" v-b-tooltip>
                            <b-icon-check-circle-fill v-if="plan.Situacao === 'A'"
                                                      variant="success"></b-icon-check-circle-fill>
                            <b-icon-exclamation-circle-fill v-else
                                                            :variant="variantColor(plan.Situacao)"></b-icon-exclamation-circle-fill>

                        </td>
                        <td>{{plan.Numero}}</td>
                        <td>{{plan.Inicio | moment('DD/MM/YYYY')}}</td>
                        <td :title="plan.dados_plano.Descricao">{{plan.dados_plano.Codigo}} -
                            {{plan.dados_plano.Descricao}}
                        </td>
                        <td>{{plan.Vendedor.toString().toUpperCase()}}</td>
                        <td>
                            <b-button @click.prevent="printer(plan.Numero)" size="sm" variant="default">
                                <b-icon-printer :class="{'text-primary': hasPrinter(plan.Numero)}"></b-icon-printer>
                            </b-button>
                        </td>
                    </tr>
                </fragment>
                </tbody>
            </table>

        </b-card>
    </b-container>
</template>
<script>
    import {tryError} from '../../libs'

    export default {
        name: "Home",
        data: () => ({
            search: '',
            clients: [],
            errorMessage: ''
        }),
        methods: {
            onClickTitle(title) {
                this.search = title
                this.requestData()
            },
            rowClass(item, type) {
                if (!item || type !== 'row') return
                if (item.Pacote > 0) return 'table-success'
            },
            hasPrinter(code) {
                let str = localStorage.getItem("print");
                if (str === null || str === '') str = "[]";
                let data = JSON.parse(str)
                return data.includes(code.toString())
            },
            printer(code) {
                this.$router.push({
                    path: '/novo/' + code,
                })
            },
            getStatus(s) {
                let data = {
                    'A': 'Ativo',
                    'T': 'Transferido',
                    'E': 'Aguardando Instalação',
                    'C': 'Cancelado'
                };
                return data[s] || s;
            },
            variantColor(st) {
                switch (st) {
                    case 'T':
                        return 'info'
                    case 'C':
                        return 'danger'

                }

                return 'default'
            },
            enablePrint(contract) {
                /**
                 *
                 * */
                return ['A', 'E'].includes(contract.Situacao || 'A');
            },
            onInputSearch() {
                setTimeout(() => {
                    if (this.search === '' || this.search === null) this.requestData()
                }, 30)
            },
            async requestData() {
                try {
                    this.clients = []
                    this.errorMessage = ''
                    let {data} = await this.$http.get('client/list_all', {
                        params: {
                            search: this.search
                        }
                    })
                    let clients = [];
                    data.forEach((r) => {

                        let currentClient = clients.find(c => c.Codigo === r.Cliente)

                        if (!currentClient) {
                            r.clientes.Contratos = {
                                Pacotes: {},
                                Planos: []
                            }
                            currentClient = r.clientes
                            clients.push(currentClient)
                        }

                        if (r.Pacote > 0) {
                            let pacoteId = r.dados_pacote.Descricao
                            if (!currentClient.Contratos.Pacotes[pacoteId]) {
                                currentClient.Contratos.Pacotes[pacoteId] = []
                            }
                            currentClient.Contratos.Pacotes[pacoteId].push(r)
                        } else {
                            currentClient.Contratos.Planos.push(r)
                        }


                    });

                    this.clients = clients
                    console.log(this.clients)

                } catch (err) {
                    this.errorMessage = tryError(err).data.message
                }
            }
        },
        mounted() {
            this.requestData();
        },

    };
</script>
